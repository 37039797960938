<script lang="ts">
export default {
  name: 'app-breadcrumbs'
}
</script>

<script setup lang="ts" >
const inicioIcon = ref(null);
const inicioText= ref(null);

const props = defineProps({
  items: {
    type: Array
  },
  initialRoute: {
    type: String,
    default: '/'
  },
  colorInicio :{
    type: String,
    required: false
  }
});


const normalizedRoute = (item) => {


  if(!item.route){
    return false;
  }
  if(item.route.includes('?')){
    return `/${item.route}`;
  }
  else{
    return `/${item.route}/`;
  }
};


</script>

<template lang="pug">
ul.app-breadcrumbs
    li.app-breadcrumbs__li(:style="{ color: colorInicio }")
        nuxt-link.app-breadcrumbs__link(:to="initialRoute") 
          span.icon-home
          span Inicio
        span >
    li.app-breadcrumbs__li(
        v-for="(item, index) in items" :key="index"
    )
        nuxt-link.app-breadcrumbs__link(
            v-if="!item.active", 
            :to="normalizedRoute(item)") {{ item.name }}
        span.app-breadcrumbs__link.active(v-else) {{ item.name }}
        span(
            :class="{ 'active': item.active }"
            v-if="index !== items.length - 1") >
</template>

<style lang="scss">
@import 'index';
</style>